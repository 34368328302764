@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
:root {
  /* light-mode / default theme variable values */
  --primaryBackgroundColor: white !important;
  --headingTextColor: #1de49f;
  --highlightTextColor:#129567;
  --secondaryBackgroundColor: #f5f5f5;
   --primaryLineColor: #1de49f;
   --primaryButtonColor: #1de49f !important;
   --primaryProjetCardColor: rgb(255, 255, 255);
   --primaryBoxShadow: rgba(84, 86, 88, 0.25);
   --avatarBg: #1de49f;
   --primaryHooverColor: rgb(61, 61, 61) !important;
}


  [data-theme='dark'] {
    /* dark mode */
    --primaryTextColor: rgb(202, 202, 202);
    --headingTextColor: #1de49f;
    --highlightTextColor:#1de49f;
    --primaryBackgroundColor: rgb(34, 34, 34) !important;
    --secondaryBackgroundColor: rgb(17, 17, 17);
    --primaryButtonColor: #1de49f !important;
    --primaryLineColor: #1de49f;
    --primaryHooverColor: rgb(164, 164, 164) !important;
    --primaryProjetCardColor: rgb(44, 44, 44);
    --primaryProjectTextColor: rgb(202, 202, 202);
    --primaryBoxShadow: rgba(0, 0, 0, 0.25);
     --avatarBg: rgb(49, 49, 49);
  }


@media (prefers-color-scheme: dark) {
      body {
        background: rgb(34, 34, 34) !important;
      }
    }

@media (prefers-color-scheme: light) {
  body {
        background: rgb(249, 249, 249) !important;
      }
}

.App {
  text-align: center;
  background-color: white;
  background-color: var(--primaryBackgroundColor);
  font-family: 'Roboto Condensed', sans-serif;
}

.theme-switch-button {
  border: none;
  background: white;
  background: var(--primaryBackgroundColor);
  border-radius: 50px;
  color: var(--primaryTextColor);
  outline: 0;
  margin-top: 7px;
  position: fixed;
  top: 15px;
  right: 15px;
  left: 15px;
  bottom: 93vh;
  z-index: 1000;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-switch-button svg {
  fill: var(--text-color);
}

.header-component {
  width: 100vw;
  background-color: rgb(26, 26, 26);
  box-shadow: 5px 10px 25px 0px rgba(0,0,0,0.5);
}


.header-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(26, 26, 26); 
  border-bottom: darkgray;
  height: 100vh;
  
}

.typewriter-section {
  height: 13%;
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.typewriter {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.header-section h1 {
  text-align: center;
  color: white;
  font-size: 2.5em;
  margin-bottom: 2px !important;
}
.header-section h2 {
  text-align: center;
  color:gainsboro;
  font-size: 2em;
  width: 100%;
}

.header-section h3 {
  text-align: center;
  color:gainsboro;
  font-size: 1em;
  width: 100%;
}

.avatar {
  margin-top: 2em;
}

.avatar img {
 width: 300px;
 max-width: 300px;
 border-radius: 60%;
 border: 1px solid black;
 margin-bottom: 2em;
 margin-top: 2em;
 background-color: var(--avatarBg);
}

.scrolldown-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 5vh;
}
  
.scrolldown {
	border: 2px solid #FFFFFF;
	border-radius: 30px;
	height: 46px;
	margin: 0 auto;
	text-align: center;
	width: 30px;
}

.scrolldown-p1,
.scrolldown-p2 {
	-webkit-animation-duration: 1.5s;
	        animation-duration: 1.5s;
	-webkit-animation-name: scrolldown;
	        animation-name: scrolldown;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	fill: #FFFFFF;
}
  
.scrolldown-p2 {
	-webkit-animation-delay: .75s;
	        animation-delay: .75s;
}

@-webkit-keyframes scrolldown {
	0% {
		opacity: 0;
		-webkit-transform: translate(0, -8px);
		        transform: translate(0, -8px);
	}
	50% {
		opacity: 1;
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	100% {
		opacity: 0;
		-webkit-transform: translate(0, 8px);
		        transform: translate(0, 8px);
	}
}

@keyframes scrolldown {
	0% {
		opacity: 0;
		-webkit-transform: translate(0, -8px);
		        transform: translate(0, -8px);
	}
	50% {
		opacity: 1;
		-webkit-transform: translate(0, 0);
		        transform: translate(0, 0);
	}
	100% {
		opacity: 0;
		-webkit-transform: translate(0, 8px);
		        transform: translate(0, 8px);
	}
}

.about-component {
  width: 100vw;
  background: var(--primaryBackgroundColor);
  color: var(--primaryTextColor);
}

.about-button {
  background: var(--primaryButtonColor) !important;
  height: 60px !important;
}

.about-button:hover{
  background: var(--primaryHooverColor) !important;
}


.title {
  font-weight: bolder;
  font-size: 4em;
  padding-top: 10%;
}


.tilte-line {
  width: 100px;
  border-width:5px;
  margin: 0 auto;
  background: var(--primaryLineColor);
  margin-bottom: 5%;
}

.about-main-div {
  display: flex;
  padding: 0% 5% 8% 5%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-gap: 5%;
  gap: 5%;
}

.about-main-div p {
  font-size: 1em;
}

.about-main-div h4  {
  font-weight: bolder;
  font-size: 2.5em;
  text-align: center;
  padding-top: 4%;
}

.about-text {
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
  text-align: left;
  width: 700px;
}

.about-text p {
  font-size: 1.3em;
  padding: 1% 10%;
  margin-top: 1vh;
}

.about-text img {
  width: 50px;
  padding: 2%
}

.about-text-cv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.about-photo {
  width: 400px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.about-photo img {
  margin: 0 auto;
  margin-top: 15%;
  width: 250px;
  border-radius: 50%;
}

.about-details {
  margin: 5% 1%;
  display: flex;
  width: 80%;
  padding: 2%;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 23px 30px -15px var(--primaryBoxShadow);
}

.about-details h4 {
  font-size: 3em;
}

.about-skills-div {
  display: flex;
  flex-direction: row;
}

.tech-stack-div {
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin: 0%;
    padding: 0;
    align-items: center;
    width: 50%;
}

.tech-stack-div h4 {
  font-size: 1em;
}

.sub-title-hr {
  border: 2px solid;
  width: 20%;
  margin: 0 auto;
}

.sub-title-tech-stack {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
}

.container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 80vw !important;
    margin: 0 !important;
}

.container .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0px;
    margin: 20px 2px 20px 2px;
    box-shadow: none;
    border-radius: 50%;
    background-color: transparent;

}

.container .card .icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.7s;
    
}

.container .card:nth-child(1) .icon {
    background: rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black;
}

.container .card:nth-child(1) .icon:hover {
    background: rgb(238, 219, 78); 
}

.container .card:nth-child(2) .icon {
    background:rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black;
}

.container .card:nth-child(2) .icon:hover {
    background:rgb(72, 163, 205); 
}

.container .card:nth-child(3) .icon {
    background:rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black; 
}

.container .card:nth-child(3) .icon:hover {
     background:rgb(72, 163, 205); 
}

.container .card:nth-child(4) .icon {
    background: rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black; 
}

.container .card:nth-child(4) .icon:hover {
    background: rgb(152, 194, 60); 
}

.container .card:nth-child(5) .icon {
    background: rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black; 
}

.container .card:nth-child(5) .icon:hover {
    background: rgb(152, 194, 60); 
}

.container .card:nth-child(6) .icon {
    background: rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black; 
}

.container .card:nth-child(6) .icon:hover {
    background: rgb(210, 88, 53); 
}

.container .card:nth-child(7) .icon {
    background: rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black;
}

.container .card:nth-child(7) .icon:hover {
    background: rgb(44, 80, 220); 
}


.container .card .face {
    width: 70px;
    height: 70px;
    transition: 1s;
}

.container .card .face.face1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    border-radius: 50%;
}

.container .card:hover .face.face1{
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
}

.container .card .face.face1 .content {
    opacity: 1;
    transition: 0.5s;
}

.container .card:hover .face.face1 .content {
    opacity: 1;
}

.container .card .face.face1 .content i{
    max-width: 20px;
}

.container .card .face.face2 {
    position: relative;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    box-sizing: border-box;
    border-radius: 10%;
    width: 130px;
    background-color: transparent;
}

.container .card:hover .face.face2{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    box-sizing: border-box;
    box-shadow: 10px 23px 30px 0px var(--primaryBoxShadow);
}

.container .card .face.face2 .content h3 {
    margin: 0 0 0px 0;
    padding: 0;
    font-size: 12px;
    text-align: center;
} 

.card-image {
  width: 50px;
}

.ts-logo {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  margin-top: 25px;
  margin-left: 15px;
}
.to-top-div {
  position: fixed; 
  right: 0%;
  justify-content: center;
  align-content: center;
  bottom: 13vh;
  z-index: 1000;
  cursor: pointer;
  opacity: 0.5;
}

.to-top-div img {
  padding-right: 10%;
  width: 80px;
   -webkit-animation: slideIn 1s forwards;
  animation: slideIn 1s forwards;
} 

.to-top-div:hover {
  opacity: 1;
} 

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slideIn {
  0% {
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.projects-component {
  width: 100vw;
  padding-top: 3vh;
  background: var(--secondaryBackgroundColor);
  color: var(--primaryTextColor);
}


.title {
  font-weight: bolder;
  font-size: 4em;
}

.projects-main-div {
  margin-top: 5vh;
  padding: 0% 5% 8% 5%;
}

.projects-content-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 4vh;
  padding: 3%;
  grid-gap: 3%;
  gap: 3%;
}

.project-item {
  display: flex;
  margin: 2%;
}

.project-item:hover > .label-project {
  background-color: gray;
  -webkit-transform: translatex(50px);
          transform: translatex(50px);
  transition: 1s ease-in;
}

.label-project {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 10%;
  margin-right: -2%;
  background-color:#1de49f;
  width: 40px;
  height: 180px;
  box-shadow: 1px 5px 20px 8px var(--primaryBoxShadow);
  -webkit-transform: translatex(0px);
          transform: translatex(0px);
  transition: 1s ease-in;     
}

.label-project h2{
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  font-size: 1.5em;
  color: #515151;
}

.container-project {
  position: relative;
  margin: 2%;
  height: 380px;
  width: 290px;
  overflow: hidden;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
  transition: box-shadow 0.3s ease-out;
}

.container-project:hover {
  box-shadow: 1px 2px 10px rgba(0,0,0,0.5);
}

.img-container-project {
  background-color: #000;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  z-index: 2;
}

.img-container-project:hover {
	cursor: pointer;
}

.container-project:hover .img-container-project {
	-webkit-transform: translateY(-110px);
	        transform: translateY(-110px);
}

.img-container-project > img {
  transition: opacity 0.3s ease-out;
  width: 100%;
  height: 100%;
}

.container-project:hover > .img-container-project > img {
  opacity: 0.5;
}

.project-more {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 3;
  margin: 0;
  padding: 0;
}

.project-more > li {
  list-style: none;
}

.project-more > li > a {
  text-align: center;
  display: block;
  justify-content: center;
  align-content: center;
  margin: 0 7px;
  opacity: 0;
  -webkit-transform: translateY(200px);
          transform: translateY(200px);
  transition: all 0.3s ease-out;
}

.container-project:hover > .project-more > li > a {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.project-more > li > a > .fa {
  font-size: 24px;
  line-height: 50px;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.project-more > li > a:hover > .fa {
  -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
}

.container-project:hover .project-more li:nth-child(1) a {
	transition-delay: 0.3s;
}

.container-project:hover .project-more li:nth-child(2) a {
	transition-delay: 0.5s;
}

.user-info {
  position: absolute;
	bottom: 0;
	left: 0;
	height: 120px;
	width: 100%;
	box-sizing: border-box;
	padding: 2px;
  text-align: center;
  background-color: var(--primaryProjetCardColor);
  color: var(--primaryProjectTextColor);
}

.user-info > h2 {
  padding-top: 4px;
  margin: 10px 0;
  font-weight: bolder;
}

.user-info > span {
  font-size: 14px;
  color: var(--primaryProjectTextColor);
}
.footer-component {
  width: 100vw;
  background-color: rgb(26, 26, 26);
}

.footer {
  background-color: rgb(26, 26, 26);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: gainsboro;
  padding: 3%;
}

.contact-form-div {
  box-shadow: 5px 10px 25px 0px rgba(0,0,0,0.5);
  padding: 20px;
  margin: 50px;
  min-width: 350px;
}

.icons {
  height: 100px;
  padding-bottom: 100px;
}
.icon {
  width: 80px;
  padding: 5%;
}

.icon:hover {
  opacity: 0.5;
}

.footer h2 {
  color: white;
  font-size: 1.5em;
}

.footer h4{
  font-size: 1em;
}

.footer h3{
  font-size: 1em;
}

input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
    font-size: 0.75em;
    color: #999;
    top: -5px;
    transition: all 0.225s ease;
}

.styled-input {
    float: left;
    width: 40vw;
    min-width: 300px;
    margin: 1rem 0;
    position: relative;
    border-radius: 4px;
}

@media only screen and (max-width: 768px){
    .styled-input {
        width:100%;
    }
}

.Contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.styled-input label {
    color: #999;
    padding: 1.3rem 30px 1rem 30px;
    position: absolute;
    top: 10px;
    left: 0;
    transition: all 0.25s ease;
    pointer-events: none;
}

.styled-input.wide { 
    width: 40vw;
    min-width: 300px;
    max-width: 100%;
}

input,
textarea {
    padding: 30px;
    border: 0;
    width: 100%;
    font-size: 1rem;
    background-color: #2d2d2d;
    color: white;
    border-radius: 4px;
}

input:focus,
textarea:focus { outline: 0; }

input:focus ~ span,
textarea:focus ~ span {
    width: 100%;
    transition: all 0.075s ease;
}

textarea {
    width: 100%;
    min-height: 15em;
}

.input-container {
    width: 650px;
    max-width: 100%;
    margin: 20px auto 25px auto;
}

@media (max-width: 768px) {
    .submit-btn {
        width:100%;
        float: none;
        text-align:center;
    }
}

#submit-button {
    width: 200px !important;
    margin-top: 0 !important;
    background-color: var(--primaryButtonColor) !important;
}

#submit-button:hover {
    background-color: var(--primaryHooverColor) !important;
}


input[type=checkbox] + label {
  color: #ccc;
  font-style: italic;
} 

input[type=checkbox]:checked + label {
  color: #f00;
  font-style: normal;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 87vw;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #b9b9b9;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background-color: #1de49f;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 100px !important;
  width: 100px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #1de49f;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(0, 0, 0, 0.9);
  padding: 2.5em 0.5em 0;
  font-size: 2em;
  font-family: 'Roboto Condensed', sans-serif !important;

}

.bm-menu a {
  text-decoration: none;
  color: rgb(117, 117, 117);
  padding: 10%;
  border: none;
  outline : none;
}

.bm-menu a:hover {
  text-decoration: none;
  color: #1de49f;
  padding: 10%;
  border: none;
  outline : none;
}


/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: rgba(0, 0, 0, 0.9);
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.1);
}

.icons-navbar {
  margin-top: 20vh;
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: center;
}

.icons-navbar p {
  font-size: 0.6em;
  color: white;
  margin-left: 9%;
}

.icon-nav {
  width: 50px;

}
.icon-nav:hover{
  opacity: 0.7;
}
