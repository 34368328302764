/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 87vw;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #b9b9b9;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background-color: #1de49f;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 100px !important;
  width: 100px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #1de49f;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(0, 0, 0, 0.9);
  padding: 2.5em 0.5em 0;
  font-size: 2em;
  font-family: 'Roboto Condensed', sans-serif !important;

}

.bm-menu a {
  text-decoration: none;
  color: rgb(117, 117, 117);
  padding: 10%;
  border: none;
  outline : none;
}

.bm-menu a:hover {
  text-decoration: none;
  color: #1de49f;
  padding: 10%;
  border: none;
  outline : none;
}


/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: rgba(0, 0, 0, 0.9);
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.1);
}

.icons-navbar {
  margin-top: 20vh;
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: center;
}

.icons-navbar p {
  font-size: 0.6em;
  color: white;
  margin-left: 9%;
}

.icon-nav {
  width: 50px;

}
.icon-nav:hover{
  opacity: 0.7;
}