
.projects-component {
  width: 100vw;
  padding-top: 3vh;
  background: var(--secondaryBackgroundColor);
  color: var(--primaryTextColor);
}


.title {
  font-weight: bolder;
  font-size: 4em;
}

.projects-main-div {
  margin-top: 5vh;
  padding: 0% 5% 8% 5%;
}

.projects-content-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 4vh;
  padding: 3%;
  gap: 3%;
}

.project-item {
  display: flex;
  margin: 2%;
}

.project-item:hover > .label-project {
  background-color: gray;
  transform: translatex(50px);
  transition: 1s ease-in;
}

.label-project {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 10%;
  margin-right: -2%;
  background-color:#1de49f;
  width: 40px;
  height: 180px;
  box-shadow: 1px 5px 20px 8px var(--primaryBoxShadow);
  transform: translatex(0px);
  transition: 1s ease-in;     
}

.label-project h2{
  transform: rotate(-90deg);
  font-size: 1.5em;
  color: #515151;
}

.container-project {
  position: relative;
  margin: 2%;
  height: 380px;
  width: 290px;
  overflow: hidden;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
  transition: box-shadow 0.3s ease-out;
}

.container-project:hover {
  box-shadow: 1px 2px 10px rgba(0,0,0,0.5);
}

.img-container-project {
  background-color: #000;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: transform 0.3s ease-out;
  z-index: 2;
}

.img-container-project:hover {
	cursor: pointer;
}

.container-project:hover .img-container-project {
	transform: translateY(-110px);
}

.img-container-project > img {
  transition: opacity 0.3s ease-out;
  width: 100%;
  height: 100%;
}

.container-project:hover > .img-container-project > img {
  opacity: 0.5;
}

.project-more {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  margin: 0;
  padding: 0;
}

.project-more > li {
  list-style: none;
}

.project-more > li > a {
  text-align: center;
  display: block;
  justify-content: center;
  align-content: center;
  margin: 0 7px;
  opacity: 0;
  transform: translateY(200px);
  transition: all 0.3s ease-out;
}

.container-project:hover > .project-more > li > a {
  transform: translateY(0);
  opacity: 1;
}

.project-more > li > a > .fa {
  font-size: 24px;
  line-height: 50px;
  transition: transform 0.3s ease-out;
}

.project-more > li > a:hover > .fa {
  transform: rotateY(360deg);
}

.container-project:hover .project-more li:nth-child(1) a {
	transition-delay: 0.3s;
}

.container-project:hover .project-more li:nth-child(2) a {
	transition-delay: 0.5s;
}

.user-info {
  position: absolute;
	bottom: 0;
	left: 0;
	height: 120px;
	width: 100%;
	box-sizing: border-box;
	padding: 2px;
  text-align: center;
  background-color: var(--primaryProjetCardColor);
  color: var(--primaryProjectTextColor);
}

.user-info > h2 {
  padding-top: 4px;
  margin: 10px 0;
  font-weight: bolder;
}

.user-info > span {
  font-size: 14px;
  color: var(--primaryProjectTextColor);
}