.footer-component {
  width: 100vw;
  background-color: rgb(26, 26, 26);
}

.footer {
  background-color: rgb(26, 26, 26);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: gainsboro;
  padding: 3%;
}

.contact-form-div {
  box-shadow: 5px 10px 25px 0px rgba(0,0,0,0.5);
  padding: 20px;
  margin: 50px;
  min-width: 350px;
}

.icons {
  height: 100px;
  padding-bottom: 100px;
}
.icon {
  width: 80px;
  padding: 5%;
}

.icon:hover {
  opacity: 0.5;
}

.footer h2 {
  color: white;
  font-size: 1.5em;
}

.footer h4{
  font-size: 1em;
}

.footer h3{
  font-size: 1em;
}
