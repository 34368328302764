.to-top-div {
  position: fixed; 
  right: 0%;
  justify-content: center;
  align-content: center;
  bottom: 13vh;
  z-index: 1000;
  cursor: pointer;
  opacity: 0.5;
}

.to-top-div img {
  padding-right: 10%;
  width: 80px;
   -webkit-animation: slideIn 1s forwards;
  -moz-animation: slideIn 1s forwards;
  animation: slideIn 1s forwards;
} 

.to-top-div:hover {
  opacity: 1;
} 

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}