@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');


.about-component {
  width: 100vw;
  background: var(--primaryBackgroundColor);
  color: var(--primaryTextColor);
}

.about-button {
  background: var(--primaryButtonColor) !important;
  height: 60px !important;
}

.about-button:hover{
  background: var(--primaryHooverColor) !important;
}


.title {
  font-weight: bolder;
  font-size: 4em;
  padding-top: 10%;
}


.tilte-line {
  width: 100px;
  border-width:5px;
  margin: 0 auto;
  background: var(--primaryLineColor);
  margin-bottom: 5%;
}

.about-main-div {
  display: flex;
  padding: 0% 5% 8% 5%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5%;
}

.about-main-div p {
  font-size: 1em;
}

.about-main-div h4  {
  font-weight: bolder;
  font-size: 2.5em;
  text-align: center;
  padding-top: 4%;
}

.about-text {
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
  text-align: left;
  width: 700px;
}

.about-text p {
  font-size: 1.3em;
  padding: 1% 10%;
  margin-top: 1vh;
}

.about-text img {
  width: 50px;
  padding: 2%
}

.about-text-cv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.about-photo {
  width: 400px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.about-photo img {
  margin: 0 auto;
  margin-top: 15%;
  width: 250px;
  border-radius: 50%;
}

.about-details {
  margin: 5% 1%;
  display: flex;
  width: 80%;
  padding: 2%;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 23px 30px -15px var(--primaryBoxShadow);
}

.about-details h4 {
  font-size: 3em;
}

.about-skills-div {
  display: flex;
  flex-direction: row;
}

.tech-stack-div {
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin: 0%;
    padding: 0;
    align-items: center;
    width: 50%;
}

.tech-stack-div h4 {
  font-size: 1em;
}

.sub-title-hr {
  border: 2px solid;
  width: 20%;
  margin: 0 auto;
}

.sub-title-tech-stack {
  display: flex;
  flex-direction: column;
  margin-top: 10%;
}

.container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 80vw !important;
    margin: 0 !important;
}

.container .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0px;
    margin: 20px 2px 20px 2px;
    box-shadow: none;
    border-radius: 50%;
    background-color: transparent;

}

.container .card .icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.7s;
    
}

.container .card:nth-child(1) .icon {
    background: rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black;
}

.container .card:nth-child(1) .icon:hover {
    background: rgb(238, 219, 78); 
}

.container .card:nth-child(2) .icon {
    background:rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black;
}

.container .card:nth-child(2) .icon:hover {
    background:rgb(72, 163, 205); 
}

.container .card:nth-child(3) .icon {
    background:rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black; 
}

.container .card:nth-child(3) .icon:hover {
     background:rgb(72, 163, 205); 
}

.container .card:nth-child(4) .icon {
    background: rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black; 
}

.container .card:nth-child(4) .icon:hover {
    background: rgb(152, 194, 60); 
}

.container .card:nth-child(5) .icon {
    background: rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black; 
}

.container .card:nth-child(5) .icon:hover {
    background: rgb(152, 194, 60); 
}

.container .card:nth-child(6) .icon {
    background: rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black; 
}

.container .card:nth-child(6) .icon:hover {
    background: rgb(210, 88, 53); 
}

.container .card:nth-child(7) .icon {
    background: rgba(69, 69, 69, 0.9);
    border-radius: 50%;
    border: black;
}

.container .card:nth-child(7) .icon:hover {
    background: rgb(44, 80, 220); 
}


.container .card .face {
    width: 70px;
    height: 70px;
    transition: 1s;
}

.container .card .face.face1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0px);
    border-radius: 50%;
}

.container .card:hover .face.face1{
    transform: translateY(0px);
}

.container .card .face.face1 .content {
    opacity: 1;
    transition: 0.5s;
}

.container .card:hover .face.face1 .content {
    opacity: 1;
}

.container .card .face.face1 .content i{
    max-width: 20px;
}

.container .card .face.face2 {
    position: relative;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    box-sizing: border-box;
    border-radius: 10%;
    width: 130px;
    background-color: transparent;
}

.container .card:hover .face.face2{
    transform: translateY(0);
    box-sizing: border-box;
    box-shadow: 10px 23px 30px 0px var(--primaryBoxShadow);
}

.container .card .face.face2 .content h3 {
    margin: 0 0 0px 0;
    padding: 0;
    font-size: 12px;
    text-align: center;
} 

.card-image {
  width: 50px;
}

.ts-logo {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  margin-top: 25px;
  margin-left: 15px;
}