@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
 
:root {
  /* light-mode / default theme variable values */
  --primaryBackgroundColor: white !important;
  --headingTextColor: #1de49f;
  --highlightTextColor:#129567;
  --secondaryBackgroundColor: #f5f5f5;
   --primaryLineColor: #1de49f;
   --primaryButtonColor: #1de49f !important;
   --primaryProjetCardColor: rgb(255, 255, 255);
   --primaryBoxShadow: rgba(84, 86, 88, 0.25);
   --avatarBg: #1de49f;
   --primaryHooverColor: rgb(61, 61, 61) !important;
}


  [data-theme='dark'] {
    /* dark mode */
    --primaryTextColor: rgb(202, 202, 202);
    --headingTextColor: #1de49f;
    --highlightTextColor:#1de49f;
    --primaryBackgroundColor: rgb(34, 34, 34) !important;
    --secondaryBackgroundColor: rgb(17, 17, 17);
    --primaryButtonColor: #1de49f !important;
    --primaryLineColor: #1de49f;
    --primaryHooverColor: rgb(164, 164, 164) !important;
    --primaryProjetCardColor: rgb(44, 44, 44);
    --primaryProjectTextColor: rgb(202, 202, 202);
    --primaryBoxShadow: rgba(0, 0, 0, 0.25);
     --avatarBg: rgb(49, 49, 49);
  }


@media (prefers-color-scheme: dark) {
      body {
        background: rgb(34, 34, 34) !important;
      }
    }

@media (prefers-color-scheme: light) {
  body {
        background: rgb(249, 249, 249) !important;
      }
}

.App {
  text-align: center;
  background-color: var(--primaryBackgroundColor);
  font-family: 'Roboto Condensed', sans-serif;
}

.theme-switch-button {
  border: none;
  background: var(--primaryBackgroundColor);
  border-radius: 50px;
  color: var(--primaryTextColor);
  outline: 0;
  margin-top: 7px;
  position: fixed;
  top: 15px;
  right: 15px;
  left: 15px;
  bottom: 93vh;
  z-index: 1000;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-switch-button svg {
  fill: var(--text-color);
}