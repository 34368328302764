
.header-component {
  width: 100vw;
  background-color: rgb(26, 26, 26);
  box-shadow: 5px 10px 25px 0px rgba(0,0,0,0.5);
}


.header-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(26, 26, 26); 
  border-bottom: darkgray;
  height: 100vh;
  
}

.typewriter-section {
  height: 13%;
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.typewriter {
  width: fit-content;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.header-section h1 {
  text-align: center;
  color: white;
  font-size: 2.5em;
  margin-bottom: 2px !important;
}
.header-section h2 {
  text-align: center;
  color:gainsboro;
  font-size: 2em;
  width: 100%;
}

.header-section h3 {
  text-align: center;
  color:gainsboro;
  font-size: 1em;
  width: 100%;
}

.avatar {
  margin-top: 2em;
}

.avatar img {
 width: 300px;
 max-width: 300px;
 border-radius: 60%;
 border: 1px solid black;
 margin-bottom: 2em;
 margin-top: 2em;
 background-color: var(--avatarBg);
}

.scrolldown-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 5vh;
}
  
.scrolldown {
	border: 2px solid #FFFFFF;
	border-radius: 30px;
	height: 46px;
	margin: 0 auto;
	text-align: center;
	width: 30px;
}

.scrolldown-p1,
.scrolldown-p2 {
	animation-duration: 1.5s;
	animation-name: scrolldown;
	animation-iteration-count: infinite;
	fill: #FFFFFF;
}
  
.scrolldown-p2 {
	animation-delay: .75s;
}

@keyframes scrolldown {
	0% {
		opacity: 0;
		transform: translate(0, -8px);
	}
	50% {
		opacity: 1;
		transform: translate(0, 0);
	}
	100% {
		opacity: 0;
		transform: translate(0, 8px);
	}
}
